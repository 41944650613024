import Script from "next/script";
import { extractScript, extractNoScript } from "utils/StringUtils";
import { v4 as uuidv4 } from 'uuid';

const RenderScripts =({scriptsList, scriptPosition}: {scriptsList: any, scriptPosition: string}) => {

    const strategy = 'lazyOnload';

    return (
        scriptsList.filter((src: any)=> Boolean(src.scriptEnable) === true && src.scriptContent.trim() !== '').map((src: any)=>{
            const currentContentArray = src?.scriptContent?.split(/<script|<noscript>/);

            return currentContentArray.map((item: any)=>{
                let currentScript = item;
                const idScript = uuidv4();

                const fullScript = ((`<script${currentScript}`).toString());

                const currentRef =  fullScript.includes('<script src=') ? currentScript?.match(/src="([^"]*)/)[1] : '';

                if(currentRef){
                    return <Script defer src={currentRef} key={`${scriptPosition}-${idScript}`} strategy={strategy}/>;
                }

                if(item.includes('</script>')) {
                    currentScript = '<script' + item;
                    return <Script defer id={`${scriptPosition}-${idScript}`} key={`${scriptPosition}-${idScript}`} strategy={strategy} dangerouslySetInnerHTML={{__html: extractScript(currentScript) || ''}} />;
                } 
                else if(item.includes('</noscript>')) {
                    currentScript = '<noscript>' + item;
                    return <noscript key={`no-${scriptPosition}-${idScript}`} dangerouslySetInnerHTML={{__html: extractNoScript(currentScript) || ''}}/>;
                }
            });
        })
    );
};

export default RenderScripts;
